import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from 'react-router-dom';

export default function App(){
  return(
      <Router>
        <nav>
          <Link to="/">Home</Link>
            <Link to="/users">Users</Link>
            <Link to="/about">About</Link>
        </nav>
          <Routes>
            <Route path="/about" element={  <About />} />

            <Route path="/users" element={  <Users />}/>

            <Route path="/"  element={  <Home />}/>
          </Routes>
      </Router>
  );
}

const Home = () => <h2>Главная</h2>;

const About = () => <h2>Контакты</h2>;

const Users = () => <h2>Пользователи</h2>;